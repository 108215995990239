import {
  getMessaging, getToken, onMessage, isSupported,
} from 'firebase/messaging'
import store from '@/store/app/notification'

function subscribeTokenToTopic(token, topic) {
  fetch(`/api/v1/fcm/${token}/topic/${topic}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: `key=${process.env.VUE_APP_FCM_SERVER_KEY}`,
    }),
  })
    .then(response => {
      if (response.status < 200 || response.status >= 400) {
        console.log(response.status, response)
      } else console.log(`subscribed to ${topic}`)
    })
    .catch(error => {
      console.error(error.result)
    })
  return true
}

const fcm = async topics => {
  const prefix = process.env.VUE_APP_FCM_PREFIX
  try {
    const fcmSupported = await isSupported()
    if (fcmSupported) {
      const messaging = getMessaging()
      getToken(messaging, { vapidKey: process.env.VUE_APP_VAP_ID })
        .then(currentToken => {
          if (currentToken) {
            store.state.enabledPushNotification = 1
            topics.forEach(topic => {
              subscribeTokenToTopic(currentToken, topic)
            })
          }
        })
        .catch(err => {
          console.log(err.message)
          if (err.message.indexOf('blocked') !== -1) store.state.blockedPushNotification = 1
          else if (err.message.indexOf('push service error') !== -1) store.state.enabledPushNotification = 0
          else console.log(err.message)
        })

      onMessage(messaging, payload => {
        const topic = JSON.parse(payload.data?.data)
        const handlerName = topic.from.replace(`${prefix}.`, '').replace('.', '_')
        const handler = require(`./topics/${handlerName}.js`)
        if (!handler) return
        handler.default.handle(topic)
      })

      // Otherwise, we need to ask the user for permission
      if (Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    }
  } catch (err) {
    console.log(err)
  }
}

const unsubscribeToken = async topic => {
  const fcmSupported = await isSupported()
  if (fcmSupported) {
    const messaging = getMessaging()
    if (messaging) {
      getToken(messaging, { vapidKey: process.env.VUE_APP_VAP_ID }).then(token => {
        fetch('/api/v1:batchRemove', {
          method: 'POST',
          headers: new Headers({
            Authorization: `key=${process.env.VUE_APP_FCM_SERVER_KEY}`,
          }),
          body: JSON.stringify({
            registration_tokens: [token],
            to: `/topics/${topic}`,
          }),

        })
          .then(response => {
            if (response.status < 200 || response.status >= 400) {
              console.log(response.status, response)
            }
            console.log(`Unsubscribed to ${topic}`)
          })
          .catch(error => {
            console.error(error.result)
          })
        return true
      })
    }
  }
}

export { fcm, unsubscribeToken }
