<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-b-tooltip="'Notifications'"
        :badge="unReadNotifications"
        badge-classes="bg-danger"
        class="text-body text-primary"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          v-if="unReadNotifications"
          pill
          variant="light-primary"
        >
          {{ unReadNotifications }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      @scroll="fetchMoreNotification"
    >
      <!-- Account Notification -->
      <template v-if=" notifications.data && notifications.data.length">
        <a
          v-for="notification,i in notifications.data"
          :key="i"
          @click="gotoNotification(notification,i)"
        >

          <component
            :is="notification.notification.__typename"
            :notification="notification"
            :un-read-notifications="unReadNotifications"
            @refetch="getNotifications"
          />

        </a>
        <div class="text-center notification-card p-1 justify-content-center">
          <b-spinner v-if="isProcessing" />
        </div>
      </template>
      <p
        v-if="notifications.data && notifications.data.length==0"
        class="px-1 pt-1"
      >
        No New Notifications
      </p>
    </vue-perfect-scrollbar>

    <!-- Notification Footer -->
    <li class="dropdown-menu-footer justify-content-right text-right">
      <a
        v-if="unReadNotifications"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        class="text-primary"
        @click="$bvModal.show('mark-all-as-read')"
      >Mark All as Read</a>
    </li>

    <b-modal
      id="mark-all-as-read"
      title="Mark All Notification as Read"
      ok-title="Confirm"
      ok-variant="success"
      ok-only
      hide-cancel
      @ok="markAllAsRead"
    >
      <p>
        Are you sure you want mark all notification as read ?
      </p>
    </b-modal>
  </b-nav-item-dropdown>

</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BNavItemDropdown, BBadge, BMedia, BAvatar, BButton, BModal, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import NotificationInvoiceVerification from './notifications/NotificationInvoiceVerification.vue'
import NotificationManualTimeAction from './notifications/NotificationManualTimeAction.vue'
import NotificationTimeLimitChange from './notifications/NotificationTimeLimitChange.vue'
import NotificationTimeLimitCross from './notifications/NotificationTimeLimitCross.vue'
import NotificationFinalizePayment from './notifications/NotificationFinalizePayment.vue'
import NotificationWalletAction from './notifications/NotificationWalletAction.vue'
import NotificationBankWithdraw from './notifications/NotificationBankWithdraw.vue'
import NotificationPermissionUpdate from './notifications/NotificationPermissionUpdate.vue'
import NotificationUserStatusChangeInProject from './notifications/NotificationUserStatusChangeInProject.vue'
import NotificationPaidInvoiceVerification from './notifications/NotificationPaidInvoiceVerification.vue'
import NotificationWalletShareInvite from './notifications/NotificationWalletShareInvite.vue'
import NotificationWalletShareRevoke from './notifications/NotificationWalletShareRevoke.vue'
import NotificationWalletTransferAction from './notifications/NotificationWalletTransferAction.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BModal,
    BSpinner,
    NotificationManualTimeAction,
    NotificationTimeLimitChange,
    NotificationTimeLimitCross,
    NotificationFinalizePayment,
    NotificationWalletAction,
    NotificationInvoiceVerification,
    NotificationBankWithdraw,
    NotificationPermissionUpdate,
    NotificationUserStatusChangeInProject,
    NotificationPaidInvoiceVerification,
    NotificationWalletShareInvite,
    NotificationWalletShareRevoke,
    NotificationWalletTransferAction,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      perfectScrollbarSettings: {
        wheelPropagation: false,
      },
      currentPage: 1,
      isProcessing: false,
    }
  },
  computed: {
    notifications: {
      get() {
        return this.$store.state.notification.notifications
      },
      set(value) {
        this.$store.commit('notification/SET_NOTIFICATION', value)
      },

    },
    unReadNotifications: {
      get() {
        return this.$store.state.notification.unReadNotifications > 0 ? this.$store.state.notification.unReadNotifications : 0
      },

      set(val) {
        this.$store.commit('notification/SET_UNREAD_NOTIFICATIONS', val)
      },
    },
  },
  mounted() {
    this.getNotifications()
  },
  methods: {
    async getNotifications() {
      this.isProcessing = true
      const project = this.$store.state.project?.project
      await this.$store.dispatch('notification/fetchNotifications', { projectUid: project.uuid })
      this.isProcessing = false
    },
    gotoNotification(notification, index) {
      if (!notification.isRead) {
        this.markAsRead(notification.uuid, index)
      }
      if (!notification.link || this.$route.name === notification?.link) return
      this.$router.push(notification.link)
    },
    markAsRead(uuid, index) {
      if (!this.notifications.data[index].isRead) {
        useApollo.users.markNotificationAsRead({ uuid }).then(() => {
          this.notifications.data[index].isRead = true
          this.unReadNotifications -= 1
        }).finally()
      }
    },
    markAllAsRead() {
      const project = this.$store.state.project?.project
      useApollo.users.markAllNotificationsAsRead({ projectUid: project.uuid }).then(() => {
        this.unReadNotifications = 0
      })
    },
    async fetchMoreNotification(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target
      if (scrollHeight - (scrollTop + offsetHeight) < 5 && !this.isProcessing && this.notifications.hasMorePages) {
        const project = this.$store.state.project?.project
        this.isProcessing = true
        this.currentPage += 1
        await this.$store.dispatch('notification/fetchNotifications', { projectUid: project.uuid, page: this.currentPage })
        this.isProcessing = false
      }
    },
  },
}
</script>
