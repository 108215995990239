<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="32"
        :variant="displayNotification.type"
        :src="getAvatar(displayNotification.avatar)"
      >
        <feather-icon
          v-if="!displayNotification.avatar"
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <p class="media-heading">
      <span class="font-weight-bolder">
        {{ displayNotification.title }}
      </span>
    </p>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>
  </b-media>
</template>
<script>
import { BMedia, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    displayNotification() {
      const currentUser = JSON.parse(localStorage.getItem('userData'))
      const {
        notification, isRead, uuid, createdAt,
      } = this.notification

      const notificationUser = currentUser.uuid === notification.user.uuid ? 'You have' : `${notification.user.name} has`
      const subtitle = `${notificationUser} crossed the ${notification.type.toLowerCase()} limit of ${notification.hours} hrs.`
      return {
        title: 'Time Limit Crossed!!', subtitle, type: 'light-danger', icon: 'AlertTriangleIcon', avatar: notification.user.avatar, isRead, uuid, createdAt,
      }
    },
  },
}
</script>
