<template>
  <div>
    <div
      ref="appContent"
      class="app-content content"
      data-scrollbar
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
    >
      <div class="content-overlay" />
      <div class="header-navbar-shadow" />
      <div
        class="content-wrapper pb-2"
        :class="contentWidth === 'boxed' ? 'container p-0' : null"
      >
        <slot name="breadcrumb">
          <app-breadcrumb />
        </slot>
        <div class="content-body overflow-hidden">
          <transition
            :name="routerTransition"
            mode="out-in"
          >
            <slot />
          </transition>
        </div>
      </div>
    </div>

    <portal-target name="lightbox" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import Scrollbar from 'smooth-scrollbar'
import { getUserData } from '@/auth/utils'
import { fcm } from '@/utils/fcm'
import { TOPICS } from '@/const/common'

// class DisableScroll extends Scrollbar.ScrollbarPlugin {
//   static pluginName = 'disableScroll';

//   // Override the transformDelta method to modify the scrolling delta
//   transformDelta(delta, fromEvent) {
//     // Set horizontal scroll delta to 0 to disable horizontal scrolling
//     delta.x = 0
//     return delta
//   }
// }

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const self = getUserData()
    const { routerTransition, contentWidth } = useAppConfig()
    const topics = TOPICS

    return {
      routerTransition, contentWidth, topics, self,
    }
  },
  data() {
    return {
      scrollbar: null,
    }
  },
  mounted() {
    fcm(this.topics)
    // Scrollbar.use(DisableScroll)
    // Scrollbar.initAll()

    // Scrollbar.track.xAxis.element.remove()
  },
}
</script>

<style scoped>
.app-content {
  height: 100vh;
}
.content-body {
  min-height: 70vh;
}
</style>
