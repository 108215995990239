import { getUserData } from '@/auth/utils'
import store from '../store'

export default function useWallet() {
  const getWalletIcon = walletType => {
    switch (walletType) {
      case 'BANKWALLET':
      case 'BankWallet':
        return 'fa fa-university'
      case 'PAYROLLWALLET':
      case 'PayrollWallet':
        return 'fa fa-cash-register'
      case 'INTERNALWALLET':
      case 'InternalWallet':
        return 'fa fa-money-bill-1'
      default:
        return 'fas fa-briefcase'
    }
  }

  const getTransactionIcon = transactionType => {
    switch (transactionType) {
      case 'ReconciliationTransaction':
        return 'manual-transaction.png'
      case 'WalletToWalletTransfer':
        return 'w2w-transfer.png'
      default:
        return null
    }
  }

  const isWalletShared = (wallet, userType = 'users', walletType = 'wallet') => {
    if (walletType === 'family') {
      if (!wallet.shares?.length) return false
      if (userType === 'users') return wallet.shares?.[0]?.owner?.uuid !== getUserData().uuid
      return wallet.shares?.[0]?.owner?.uuid !== store.state.project.selectedCompany
    }
    if (userType === 'users') return wallet.owner?.uuid !== getUserData().uuid
    return wallet.owner?.uuid !== store.state.project.selectedCompany
  }

  const GET_SHARED_DIRECTION = (direction, icon = false) => {
    switch (direction) {
      case 'INBOUND':
        return icon ? 'fa-download' : 'Inbound'
      case 'OUTBOUND':
        return icon ? 'fa-upload' : 'Outbound'
      default:
        return icon ? 'fa-exchange-alt' : 'Inbound and Outbound'
    }
  }

  const getVendorIcon = type => {
    switch (type) {
      case 'User':
        return 'far fa-user'
      case 'Company':
        return 'fas fa-building'
      default:
        return 'fas fa-user-tie'
    }
  }

  return {
    getWalletIcon,
    getVendorIcon,
    getTransactionIcon,
    isWalletShared,
    GET_SHARED_DIRECTION,
  }
}
