export default [
  {
    locale: 'en',
    img: require('@/assets/images/flags/en.png'),
    name: 'English',
  },

  // {
  //   locale: 'es',
  //   img: require('@/assets/images/flags/es.png'),
  //   name: 'Español',
  // },

  {
    locale: 'np',
    img: require('@/assets/images/flags/np.png'),
    name: 'नेपाली',
  },
  {
    locale: 'in',
    img: require('@/assets/images/flags/in.png'),
    name: 'हिन्दी, हिंदी',
  },
  {
    locale: 'jp',
    img: require('@/assets/images/flags/jp.png'),
    name: '日本語',
  },
  {
    locale: 'kr',
    img: require('@/assets/images/flags/kr.png'),
    name: '한국어',
  },
]
