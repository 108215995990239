<template>
  <b-media
    class="notification-card"
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="32"
        :variant="displayNotification.type"
        :src="getAvatar(displayNotification.avatar)"
      >
        <feather-icon
          v-if="!displayNotification.avatar"
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <div class="media-heading d-flex justify-content-between">
      <p class="font-weight-bolder text-capitalize mb-0">
        {{ displayNotification.title }}
      </p>
      <div v-if="notification.notification.verificationAction === 'REQUESTED'">
        <b-badge
          v-if="notification.notification.invoice.verifiedAt"
          variant="success"
        >
          Approved
        </b-badge>
        <b-badge
          v-if="notification.notification.invoice.rejectedAt"
          variant="danger"
        >
          Rejected
        </b-badge>
      </div>
    </div>

    <small class="notification-text">{{ displayNotification.subtitle }}</small>

    <!-- Review Button -->
    <div class="text-right">
      <b-button
        v-if="!notification.notification.invoice.verifiedAt && !notification.notification.invoice.rejectedAt"
        size="sm"
        variant="primary"
        @click="showReviewModal"
      >
        Review
      </b-button>
    </div>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>

    <!-- Modal -->
    <div class="invoice-verification-modal">
      <b-modal
        :id="`review-invoice-${invoice.invoiceId}-modal`"
        title="Review Invoice"
        ok-title="Approve"
        cancel-title="Reject"
        ok-variant="success"
        cancel-variant="danger"
        no-close-on-backdrop
        :busy="isProcessing"
        @ok="updateInvoice"
        @cancel="updateInvoice"
      >
        <b-overlay
          :show="isProcessing"
          class="position-relative"
        >
          <img
            class="position-absolute invoice-img"
            src="@/assets/images/invoice/invoice.png"
            alt=""
          >
          <div class="d-flex justify-content-end align-items-baseline mb-2">
            <p class="mr-1">
              Date:
            </p>
            <h5>{{ `${FORMAT_DATE(invoice.from)} to ${FORMAT_DATE(invoice.to)}` }}</h5>
          </div>

          <div class="d-flex justify-content-between">
            <p>{{ invoice.memo }}</p>
            <h5>{{ formatAmount(invoice.paidAmount - totalExtraPayments) }}</h5>
          </div>
          <div v-if="invoice.extraPayments">
            <div
              v-for="extraPayment, index in invoice.extraPayments.data"
              :key="index"
              class="d-flex justify-content-between"
            >
              <p>{{ extraPayment.payment.record.category.displayName }} <span class="ml-1 text-muted">- {{ extraPayment.payment.record.remarks }}</span></p>
              <h5 :class="{'text-danger': extraPayment.payment.amount < 0}">
                {{ formatAmount(extraPayment.payment.amount) }}
              </h5>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <p>Total Amount</p>
            <h5>{{ formatAmount(invoice.paidAmount) }}</h5>
          </div>
        </b-overlay>
      </b-modal>
    </div>
  </b-media>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import { getUserData } from '@/auth/utils'
import {
  BMedia, BAvatar, BButton, BModal, BBadge, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BMedia,
    BAvatar,
    BButton,
    BModal,
    BBadge,
    BOverlay,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      invoice: {},
      self: getUserData(),
      isProcessing: false,
    }
  },
  computed: {
    displayNotification() {
      const {
        notification, creator, isRead, uuid, createdAt,
      } = this.notification
      const { user } = notification

      let type = ''
      let action = ''
      switch (notification.verificationAction) {
        case 'REQUESTED':
          type = 'light-info'
          action = 'verification'
          break
        case 'DECLINED':
          type = 'light-danger'
          break
        default:
          type = 'light-success'
      }

      const creatorName = creator.uuid === this.self.uuid ? 'You have' : `${creator.name} has`
      let subtitle = `${creatorName} ${notification.verificationAction.toLowerCase()} an invoice ${notification.verificationAction === 'REQUESTED' ? 'verification' : ''} (invoice Id: ${notification.invoice.invoiceId})`

      if (notification.invoice.rejectedAt) {
        subtitle = `${user.uuid === this.self.uuid ? 'You have' : `${user.name} has`} rejected the invoice (invoice Id: ${notification.invoice.invoiceId})`
        type = 'light-danger'
      }

      if (notification.invoice.verifiedAt) {
        subtitle = `${user.uuid === this.self.uuid ? 'You have' : `${user.name} has`} approved the invoice (invoice Id: ${notification.invoice.invoiceId})`
        type = 'light-success'
      }

      return {
        title: `Invoice ${action} ${notification.verificationAction.toLowerCase()}!`, subtitle, type, icon: 'userIcon', avatar: notification.user.avatar, isRead, uuid, createdAt,
      }
    },
    totalExtraPayments() {
      let total = 0
      if (this.invoice.extraPayments) {
        total = this.invoice.extraPayments.data.reduce((acc, extra) => acc + extra.payment.amount, 0)
      }
      return total
    },
  },
  methods: {
    showReviewModal() {
      const { invoiceId } = this.notification.notification.invoice
      useApollo.users.getMyInvoice({ invoiceId }).then(response => {
        [this.invoice] = response.data.me.invoices.data
      }).finally(() => { this.$bvModal.show(`review-invoice-${this.invoice.invoiceId}-modal`) })
    },
    updateInvoice(bvModal) {
      bvModal.preventDefault()
      this.isProcessing = true
      let decline = true
      if (bvModal.trigger === 'ok') decline = false

      useApollo.users.approveAdjustedInvoice({
        userUid: this.self.uuid,
        projectUid: this.$store.state.project.selectedProject,
        record: Number(this.invoice.invoiceId),
        decline,
      }).then(response => {
        this.showSuccessMessage({
          data: {
            message: response.data.approveAdjustedInvoice.message,
          },
        })
      }).finally(() => {
        this.$emit('refetch')
        this.isProcessing = false
        this.$bvModal.hide(`review-invoice-${this.invoice.invoiceId}-modal`)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-img {
  width: 200px;
  height: auto;
  top: 20px;
  right: 40px;
}
</style>

<style lang="scss">
.modal-footer .btn {
  z-index: 1;
}
</style>
