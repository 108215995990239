<template>
  <div class="call-modal-wrap">
    <div class="call-modal">
      <div class="call-info">
        <img
          v-if="caller.avatar"
          :src="getAvatar(caller.avatar)"
        >
        <div
          v-else
          class="icon-wrapper centralize text-primary"
        >
          <i class="fas fa-user-friends text-3" />
        </div>
        <p>{{ caller.type === 'group' ? caller.name : '' }}</p>
        <p><span>{{ callerName }}</span> is calling... </p>
      </div>
      <div>
        <b-button
          v-b-tooltip.hover="'Accept Call with Video'"
          variant="success"
          class="btn-icon rounded-circle call-icon"
          @click="acceptCall('video')"
        >
          <feather-icon icon="VideoIcon" />
        </b-button>
        <b-button
          v-b-tooltip.hover="'Accept Call'"
          variant="primary"
          class="ml-2 btn-icon rounded-circle call-icon"
          @click="acceptCall('audio')"
        >
          <feather-icon icon="PhoneIcon" />
        </b-button>
        <b-button
          v-b-tooltip.hover="'Decline Call'"
          class="btn-icon ml-2 rounded-circle call-icon"
          variant="danger"
          @click="declineTheCall"
        >
          <feather-icon icon="PhoneOffIcon" />
        </b-button>
      </div>

    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import EventBus from '@/event-bus'

export default {
  name: 'CallInfoModal',
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    caller: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ring: null,
      self: getUserData(),
      showCall: false,
    }
  },
  computed: {
    callerName() {
      return this.caller.participants?.find(participant => participant.uuid === this.caller.initiator).name ?? this.caller.name
    },
  },
  methods: {
    async acceptCall(callType) {
      this.$chatService.joinMediaRoom(this.caller.roomUid, callType)
      const _client = await this.$chatService.activeMediaRoom()
      EventBus.$emit('incoming', this.caller, _client)
      EventBus.$emit('user-on-call')
      this.$emit('hide-modal')
    },
    declineTheCall() {
      EventBus.$emit('call-ended')
      this.$chatService.declineMediaCall(this.caller.roomUid)
    },
    onNewChatMessageReceived(messagePayload) {
      if (messagePayload.type === 'VideoCallEnded') {
        this.ring.pause()
        this.ring.currentTime = 0
      }
    },
  },
}
</script>
