import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import common from './common'

const _t = path => i18n.t(`${path}`)

export default function (authType = 'user') {
  const menu = {}

  menu.admin = [
    ...common(true),
    ...(getUserData()?.isAdmin ? [
      {
        header: _t('Job Settings'),
        icon: 'BriefcaseIcon',
        children: [
          {
            title: _t('Manage Category'),
            route: 'job-category',
          },
          {
            title: _t('Basic Setup'),
            route: 'job-basic-setup',
          },
        ],
      },
      {
        header: _t('Admin Settings'),
        icon: 'SlidersIcon',
        route: 'admin-settings',
        title: 'Admin Settings',
      },
    ] : []),
  ]

  menu.user = [
    ...common(false),

  ]

  return menu[authType]
}
