<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="32"
        :variant="displayNotification.type"
      >
        <feather-icon
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <p class="media-heading">
      <span class="font-weight-bolder">
        {{ displayNotification.title }}
      </span>
    </p>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>
  </b-media>
</template>
<script>
import { BMedia, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    displayNotification() {
      const {
        notification, isRead, uuid, createdAt,
      } = this.notification

      let title = ''
      let subtitle = ''

      if (notification.walletAction === 'WITHDRAW') {
        title = 'Fund Withdrawal!'
        subtitle = `${this.formatAmount(notification.amount)} has been withdrawn from ${notification.share.description}`
      } else {
        title = 'Fund Deposit!'
        subtitle = `${this.formatAmount(notification.amount)} has been deposited into ${notification.share.description}`
      }

      return {
        title, subtitle, type: 'light-success', icon: 'DollarSignIcon', isRead, uuid, createdAt,
      }
    },
  },
}
</script>
