import useApollo from '@/plugins/graphql/useApollo'
import store from '@/store'

const checkUserInfoStatus = userId => {
  useApollo.users.getUserInfoStatus({ userId }).then(response => {
    const info = response.data.user.extraInformations
    if (info.total) {
      const generalInfo = info.data.find(data => data.information.__typename === 'ContactInformation')
      const bankingInfo = info.data.find(data => data.information.__typename === 'BillingInformation')

      store.dispatch('project/setInformationStatus', {
        hasGeneralInformation: !!generalInfo,
        hasBankingInformation: !!bankingInfo,
      })
    } else {
      store.dispatch('project/setInformationStatus', {
        hasGeneralInformation: false,
        hasBankingInformation: false,
      })
    }
  })
}

export default checkUserInfoStatus
