<template>
  <div>
    <!-- Enable Push Notifcation Modal -->
    <b-modal
      id="push-notification-popup"
      ok-title="Close"
      cancel-title="Later"
      cancel-variant="danger"
      @ok="requestPermission"
      @cancel="disablePushNotificationCheck"
      @hidden="onModalHidden"
    >
      <template #modal-title>
        <feather-icon
          icon="InfoIcon"
          size="24"
        />
        Enable Push Notification
      </template>
      <div
        v-if="notificationPermissions.blockedPushNotification"
        class="px-1 pb-1"
      >
        <p>We noticed that you have blocked notifications for Workzone1 on your browser. Allowing notifications will enhance your browsing experience and provide you with timely updates and notifications.</p>

        <p>
          {{ unblockInstructions }}
        </p>
        <p>
          Copy and paste on your browser's address bar:
          <strong>{{ unblockNotificationUrl }}</strong>
        </p>

        <div
          v-if="steps.unblockNotification.images && steps.unblockNotification.images.length"
          class="d-flex img-wrap"
        >
          <img
            v-for="image in steps.unblockNotification.images"
            :key="image"
            class="cursor-pointer mr-1 col-6"
            :src="require(`@/assets/images/pages/${image}`)"
            alt=""
            @click="() => { $bvModal.show('image-preview-modal') }"
          >
        </div>
      </div>
      <div
        v-else
        class="p-1"
      >
        <p>We noticed that you haven't enabled Google Services for Push Messaging on your browser (Brave or Chrome). Enabling this feature will enhance your browsing experience and provide you with timely updates and notifications.</p>

        <p>
          To enable this feature, navigate to the browser settings using the following link and enable 'Use Google services for push messaging' as shown in the image below:
        </p>
        <p>
          Copy and paste on your browser's address bar:
          <strong>{{ enableNotificationUrl }}</strong>
        </p>

        <div
          v-if="steps.enableNotification.images && steps.enableNotification.images.length"
          class="d-flex img-wrap"
        >
          <img
            v-for="image in steps.enableNotification.images"
            :key="image"
            class="cursor-pointer mr-1 col-6"
            :src="require(`@/assets/images/pages/${image}`)"
            alt=""
            @click="() => { $bvModal.show('image-preview-modal') }"
          >
        </div>
      </div>
    </b-modal>

    <!-- Image Preview -->
    <b-modal
      id="image-preview-modal"
      ok-title="Close"
      size="xl"
      ok-only
    >
      <template #modal-title>
        <feather-icon
          icon="InfoIcon"
          size="24"
        />
        Enable Push Notification
      </template>
      <template #default>
        <swiper
          v-if="(steps.unblockNotification.images && steps.unblockNotification.images.length) || (steps.enableNotification.images && steps.enableNotification.images.length)"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(image, index) in notificationPermissions.blockedPushNotification ? steps.unblockNotification.images : steps.enableNotification.images"
            :key="index"
            class="img-wrap"
          >
            <h5 class="mb-2">
              Step {{ index + 1 }}:
              {{ notificationPermissions.blockedPushNotification ? steps.unblockNotification.steps[index] : steps.enableNotification.steps[index] }}
            </h5>
            <img
              :src="require(`@/assets/images/pages/${image}`)"
              alt=""
            >
          </swiper-slide>
          <div
            slot="button-prev"
            class="swiper-button-prev"
          />
          <div
            slot="button-next"
            class="swiper-button-next"
          />
        </swiper>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  computed, getCurrentInstance, onMounted, reactive, toRefs, watch, nextTick,
} from '@vue/composition-api'
import moment from 'moment'
import { createTourDriver } from '@/views/tour/tour'
import { useRouter } from '@/@core/utils/utils'
import EventBus from '@/event-bus'

export default {
  components: {
    BModal,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter()
    const instance = getCurrentInstance()
    const store = instance.proxy.$store

    const state = reactive({
      currentBrowser: '',
      enableNotificationUrl: '',
      unblockNotificationUrl: '',
      unblockInstructions: '',
      steps: {
        enableNotification: {
          images: [],
          steps: [],
        },
        unblockNotification: {
          images: [],
          steps: [],
        },
      },
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    })
    let tourDriver = null
    const checkPopups = JSON.parse(localStorage.getItem('checkPopups')) ?? {}

    // Computed Properties
    const notificationPermissions = computed(() => ({
      enabledPushNotification: store.state.notification.enabledPushNotification,
      blockedPushNotification: store.state.notification.blockedPushNotification,
    }))
    const pushNotificationData = computed(() => checkPopups?.pushNotification ?? {})
    const pushNotificationDisabled = computed(() => pushNotificationData.value && pushNotificationData.value.disablePushNotificationCheck && instance.proxy.isAfterTODAY(pushNotificationData.value.expiresAt))

    const pushNotificationStatus = computed(() => (!pushNotificationData.value
          || !pushNotificationDisabled.value))

    const tourVisited = computed(() => JSON.parse(localStorage.getItem('tourVisited')))

    // Let's Tour
    const showTour = () => {
      store.dispatch('tour/setTouring', true)
      tourDriver = createTourDriver(router)
      tourDriver.drive(window.location.pathname === '/select-project-roadblock' ? 0 : 3)
    }

    EventBus.$off('start-tour')
    EventBus.$on('start-tour', showTour)

    // Watchers
    watch(() => notificationPermissions, val => {
      if (val.value.enabledPushNotification || val.value.blockedPushNotification) {
        if (pushNotificationStatus.value && (!val.value.enabledPushNotification || val.value.blockedPushNotification)) {
          nextTick(() => { instance.proxy.$bvModal.show('push-notification-popup') })
        } else if (!tourVisited.value) {
          nextTick(() => {
            showTour()
          })
        }
      }
    }, { immediate: true, deep: true })

    // Methods
    const onModalHidden = () => {
      if (!tourVisited.value) {
        showTour()
      }
    }

    const requestPermission = () => {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    }

    const checkBrowser = () => {
      const isChromiumBased = /Chrome|Chromium/.test(navigator.userAgent)
      const isFirefox = navigator.userAgent.includes('Firefox')

      if (isChromiumBased) {
        state.currentBrowser = 'chromium'
        state.enableNotificationUrl = 'chrome://settings/privacy'
        state.unblockNotificationUrl = 'chrome://settings/content'
        state.unblockInstructions = "To allow notifications, navigate to the browser settings using the following link. You should see 'workzone1.com' as blocked notifications, click it and then, click on the 'Reset Permissions' button to reset the blocked notification as shown in the images below:"
        state.steps.enableNotification = {
          images: ['enable_push_notification.png'],
          steps: ["Enable 'Use Google services for push messaging' and relaunch the browser"],
        }
        state.steps.unblockNotification = {
          images: ['unblock_notification_1.png', 'unblock_notification_2.png'],
          steps: ["Click on 'workzone1.com'", 'Click on Reset Permissions and Confirm'],
        }
      } else if (isFirefox) {
        state.currentBrowser = 'firefox'
        state.unblockNotificationUrl = 'about:preferences#privacy'
        state.unblockInstructions = "To allow notifications, navigate to the browser settings using the following link. Scroll down to 'Permissions' and click the Settings button for Notifications. Then, select 'Allow' and save the changes as shown in the images below:"
        state.steps.unblockNotification = {
          images: ['unblock_notification_firefox_1.png', 'unblock_notification_firefox_2.png'],
          steps: [
            "Scroll down to 'Permissions' and click the 'Settings' button for Notifications",
            "Set the status to 'Allow' and click on 'Save Changes'",
          ],
        }
      }
    }

    const disablePushNotificationCheck = () => {
      const pushNotification = {
        disablePushNotificationCheck: true,
        expiresAt: moment().add(3, 'days').format('YYYY-MM-DD'),
      }
      const newPopupChecks = {
        ...checkPopups,
        pushNotification,
      }
      localStorage.setItem('checkPopups', JSON.stringify(newPopupChecks))
    }

    onMounted(checkBrowser)

    return {
      ...toRefs(state),
      notificationPermissions,
      disablePushNotificationCheck,
      requestPermission,
      onModalHidden,
    }
  },
}
</script>

<style scoped>
.img-wrap img {
    width: 100%;
    object-fit: contain;
  }
</style>

<style lang="scss">
@import "@core/scss/base/pages/tour.scss";
</style>
