<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="32"
        :variant="displayNotification.type"
      >
        <feather-icon
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <p class="media-heading">
      <span class="font-weight-bolder">
        {{ displayNotification.title }}
      </span>
    </p>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>
  </b-media>
</template>
<script>
import { BMedia, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    displayNotification() {
      // const currentUser = JSON.parse(localStorage.getItem('userData'))
      const {
        notification, isRead, uuid, createdAt,
      } = this.notification
      // const { user } = notification
      // const displayUser = user.uuid === currentUser.uuid ? 'your' : `${user.name}'s`
      // const creatorName = creator.uuid === currentUser.uuid ? 'You have' : `${creator.name} has`

      const title = 'Fund Withdrawal!'
      const subtitle = `A bank withdrawal of ${this.formatAmount(notification.amount)} has been made from ${notification.wallet.description} - ${notification.description}`

      return {
        title, subtitle, type: 'light-success', icon: 'DollarSignIcon', isRead, uuid, createdAt,
      }
    },
  },
}
</script>
