import i18n from '@/libs/i18n'
import store from '@/store'

const _t = path => i18n.t(`${path}`)

export default function (isAdmin, isManager) {
  const checkPermission = (keys, value) => (store.getters['permissions/getPermissionsCount'](keys) ? [value] : [])

  const settings = {
    header: _t('Settings'),
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Users',
        route: 'users',
      },
      ...checkPermission('project.manageusers', {
        title: _t('Project Settings'),
        route: 'project-settings',
      }),
      ...checkPermission(['timezones.update', 'company.edit'], {
        title: _t('Company Setting'),
        route: 'companySetting',
      }),
      ...checkPermission('productivity.update', {
        title: _t('Productive Rating'),
        route: 'productive-rating',
      }),
      {
        title: _t('Companies'),
        route: 'companies',
      },
      ...isManager ? [{
        title: _t('Team'),
        route: 'team',
      }] : [],
      ...isAdmin ? [{
        title: _t('Manage Builds'),
        route: 'upload-application',
      }] : [],
      {
        title: _t('Manage Permissions'),
        route: 'manage-permissions',
      },
    ],
  }
  return settings
}
