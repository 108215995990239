import useApollo from '@/plugins/graphql/useApollo'
import EventBus from '@/event-bus'
import moment from 'moment'
import { getUserData } from '@/auth/utils'

export default {
  handle(topic) {
    const avatarSplit = topic.avatar.split('/')
    if (avatarSplit[1] === getUserData().uuid) {
      useApollo.users.getUserInfo({ uuid: getUserData().uuid }).then(response => {
        const data = response.data.me
        EventBus.$emit('updated-avatar', `${data.avatar}?r=${moment().unix()}`)
      })
    }
  },
}
