import Menu from '../horizontal/index'

const verticalMenu = []
const horizontalMenu = [...Menu()]
horizontalMenu.forEach(menu => {
  const menuObj = { ...menu }
  if (menuObj.header) {
    // eslint-disable-next-line no-param-reassign
    menuObj.title = menuObj.header
    // eslint-disable-next-line no-param-reassign
    delete menuObj.header
  }
  verticalMenu.push(menuObj)
})

export default verticalMenu
