<template>
  <b-media
    class="notification-card "
    :class="(!notification.isRead && unReadNotifications!=0) && 'notification-card--active'"
  >
    <template #aside>
      <b-avatar
        size="32"
        :variant="displayNotification.type"
        :src="getAvatar(displayNotification.avatar)"
      >
        <feather-icon
          v-if="!displayNotification.avatar"
          :icon="displayNotification.icon"
        />
      </b-avatar>
    </template>
    <p class="media-heading">
      <span class="font-weight-bolder">
        {{ displayNotification.title }}
      </span>
    </p>
    <small class="notification-text">{{ displayNotification.subtitle }}</small>
    <div class="text-right">
      <small class="text-mute">
        {{ fromNow(UTCtoLocal(displayNotification.createdAt)) }}
      </small>
    </div>
  </b-media>
</template>
<script>
import { BMedia, BAvatar } from 'bootstrap-vue'
import strings from '@/utils/strings'

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    notification: {
      type: [Array, Object],
      default: () => {},
    },
    unReadNotifications: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    displayNotification() {
      const {
        notification, creator, uuid, isRead, createdAt,
      } = this.notification
      const { user } = notification
      const currentUser = JSON.parse(localStorage.getItem('userData'))
      const link = 'manual-time'
      switch (notification.action) {
        case 'REQUESTED':
          // eslint-disable-next-line no-case-declarations
          const subtitle = `${user.name} has requested manual time `
          return {
            title: 'Manual Time Requested!!', subtitle, type: 'light-info', icon: 'ClockIcon', avatar: user.avatar, link, uuid, isRead, createdAt,
          }

        default:
          // eslint-disable-next-line no-case-declarations
          const message = `${creator.name} has ${notification.action.toLowerCase()} ${user.uuid === currentUser.uuid ? 'your' : `${user.name}'s`} manual time`
          return {
            title: `Manual Time ${strings.titleCase(notification.action)}!!`,
            subtitle: message,
            type: notification.action === 'APPROVED' ? 'light-success' : 'light-danger',
            icon: notification.action === 'APPROVED' ? 'CheckIcon' : 'XIcon',
            avatar: creator.avatar,
            link,
            uuid,
            isRead,
            createdAt,
          }
      }
    },
  },
}
</script>
